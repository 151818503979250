<template>
  <div class="col-12 pl-2 pr-2 h-100">
    <div class="row no-gutters pt-4 pb-4">
      <div class="col-lg-4">
        <h4 class="text-primary font-weight-bold ml-0 pl-0">
          Contatti {{ this.nContact }}
        </h4>
        <input
          type="checkbox"
          v-model="selectAll"
          @click="addSelectAll"
          name="selectAll"
        />
        <label for="selectAll" class="ml-2">Seleziona tutti </label>

        <!-- <b-form-checkbox
          v-model="selectAll"
          @click="addSelectAll"
          name="selectAll"
          button
        >
          Seleziona tutti
        </b-form-checkbox> -->
      </div>
      <div class="col-xl-8  mt-2 mt-xl-0">
        <div class="d-flex justify-content-xl-end align-items-center h-100">
          <b-button variant="outline-primary" v-b-modal.addActivitySelect
            >Assegna attività</b-button
          >

          <b-button
            variant="outline-primary"
            class="ml-4"
            v-b-modal.modal-archivia
            >Archivia contatti</b-button
          >
          <b-button-group class="ml-4">
            <b-button variant="outline-primary" v-on:click="downloadMethod"
              >Esporta contatti</b-button
            >
            <b-button variant="outline-primary" v-b-modal.importContact
              >Importa contatti</b-button
            >
            <b-button variant="primary" v-b-modal.newContact
              >Nuovo contatto</b-button
            >
          </b-button-group>
        </div>
      </div>
    </div>

    <b-modal
      id="newContact"
      title="Nuovo contatto"
      @hide="resetModal"
      ref="modal"
      centered
      @ok="handleOk"
      :ok-disabled="checkNewContact !== true"
      ok-only
      ok-title="Salva"
      header-class="text-primary"
      body-class="pb-0"
      footer-class="d-flex justify-content-start border-0 pt-0"
      size="xl"
    >
      <form ref="form" @submit.stop.prevent="saveLine()">
        <div class="row ">
          <div class="col-lg-6">
            <b-form-group label="Nome" label-for="firstName" class="m-input">
              <b-form-input
                id="firstName"
                v-model="newContact.firstName"
                maxlength="100"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Cognome" label-for="lastName" class="m-input">
              <b-form-input
                id="lastName"
                v-model="newContact.lastName"
                maxlength="100"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-6">
            <b-form-group
              label="Seleziona anno di nascita "
              label-for="listYear"
              class="m-input w-100 "
            >
              <b-form-select
                id="listYear"
                v-model="newContact.birthDt"
                :options="this.listYear"
              >
                <template #first>
                  <b-form-select-option
                    :value="0"
                    disabled
                  ></b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <!-- <v-date-picker class="" v-model="newContact.birthDt" format="yyyy">
              <template v-slot="{ inputValue, inputEvents }">
                <b-form-group
                  label="Data di nascita"
                  label-for="birthDt"
                  class="m-input"
                >
                  <input
                    id="birthDt"
                    class="form-control"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </b-form-group>
              </template>
            </v-date-picker> -->
            <b-form-group label="Email" label-for="email" class="m-input">
              <b-form-input
                id="email"
                v-model="newContact.email"
                type="email"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-6">
            <b-form-group
              label="Telefono"
              label-for="phoneNumber"
              class="m-input"
            >
              <b-form-input
                id="phoneNumber"
                type="tel"
                v-model="newContact.phoneNumber"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Seleziona Tipologia Attività"
              label-for="activityType_Code"
              class="m-input"
            >
              <b-form-select
                id="activityType_Code"
                v-model="newContact.activityType_Code"
                :options="listTypeActivity"
                value-field="activity.id"
                text-field="activity.name"
                @change="getListActivityNew"
              >
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Seleziona Attività"
              label-for="activity_Code"
              class="m-input"
            >
              <b-form-select
                id="activity_Code"
                v-model="newContact.activity_Code"
                :options="listActivity"
                value-field="activity.id"
                text-field="activity.name"
                :disabled="checkListActivity !== true"
              >
              </b-form-select>
            </b-form-group>
            <v-date-picker class="" v-model="newContact.eventDt" :masks="masks">
              <template v-slot="{ inputValue, togglePopover }">
                <b-form-group
                  label="Data inizio"
                  label-for="input-day"
                  class="m-input"
                >
                  <input
                    id="input-day"
                    class="form-control"
                    :value="inputValue"
                    @click="togglePopover"
                  />
                </b-form-group>
              </template>
            </v-date-picker>
          </div>
          <div class="col-lg-6">
            <div class=" w-100 pt-2 pb-3">
              <b-list-group-item
                class="d-flex justify-content-between align-items-center m-listmin"
              >
                <b-form-checkbox v-model="newContact.isNewsletter"
                  >Newsletter</b-form-checkbox
                >
              </b-list-group-item>
              <b-list-group-item
                class="d-flex justify-content-between align-items-center m-listmin"
              >
                <b-form-checkbox v-model="newContact.isPrivacy"
                  >Privacy</b-form-checkbox
                >
              </b-list-group-item>
            </div>
            <b-form-group
              label="Seleziona corso"
              label-for="contactsCourses"
              class="m-input w-100 mt-3"
            >
              <b-form-select
                id="contactsCourses"
                v-model="newContact.contactsCourses"
                :options="listCourses"
                value-field="id"
                text-field="name"
              >
                <template #first>
                  <b-form-select-option :value="0"></b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </div>

          <div class="col-12">
            <div class="w-100 ">
              <vue-editor v-model="newContact.notes"></vue-editor>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="addActivitySelect"
      title="Assegna attività"
      @hide="resetaddActivity"
      ref="modal"
      centered
      @ok="handleOkActivity"
      :ok-disabled="checkActivity !== true"
      ok-only
      ok-title="Salva"
      header-class="text-primary"
      body-class="pb-0"
      footer-class="d-flex justify-content-start border-0 pt-0"
    >
      <form ref="form" @submit.stop.prevent="addActivitySelect()">
        <b-form-group
          label="Seleziona Tipologia Attività"
          label-for="activityType_Code"
          class="m-input"
        >
          <b-form-select
            id="activityType_Code"
            v-model="addActivity.activityType_Code"
            :options="listTypeActivity"
            value-field="activity.id"
            text-field="activity.name"
            @change="getListActivity"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Seleziona Attività"
          label-for="activity_Code"
          class="m-input"
        >
          <b-form-select
            id="activity_Code"
            v-model="addActivity.activity_Code"
            :options="listActivity"
            value-field="activity.id"
            text-field="activity.name"
            :disabled="checkListActivity !== true"
          >
          </b-form-select>
        </b-form-group>

<!--          <v-date-picker class="" v-model="addActivity.eventDt" :masks="masks">-->
<!--              <template v-slot="{ inputValue, inputEvents }">-->
<!--                  <b-form-group label="Data fine*" label-for="input-dayEnd" class="m-input">-->
<!--                      <input id="input-dayEnd" class="form-control" :value="inputValue" v-on="inputEvents" required />-->
<!--                  </b-form-group>-->
<!--              </template>-->
<!--          </v-date-picker>-->

      <div class="row no-gutters justify-content-between">
          <div class="col-9">
        <v-date-picker class="" v-model="addActivity.eventDt" :masks="masks">
                  <template v-slot="{ inputValue, inputEvents }">
                      <b-form-group label="Data" label-for="input-day" class="m-input">
                          <input id="input-day" class="form-control" :value="inputValue" v-on="inputEvents"/>
            </b-form-group>
          </template>
        </v-date-picker>

<!--        <v-date-picker class="" v-model="addActivity.eventDt" :masks="masks">-->
<!--          <template v-slot="{ inputValue, togglePopover }">-->
<!--            <b-form-group label="Data inizio" label-for="input-day" class="m-input">-->
<!--              <input id="input-day" class="form-control" :value="inputValue" @click="togglePopover"/>-->
<!--            </b-form-group>-->
<!--          </template>-->
<!--        </v-date-picker>-->
          </div>
          <div class="col-3">
              <b-button
                  @click.prevent="addFakeDate"
                  type="button"
                  class="w-100"
                  style="padding: 0.95rem 0.75rem"
              >
                  Data fake
              </b-button>
          </div>
      </div>

        <b-form-group class="">
          <!--  <b-form-input
            id="note-input"
            v-model="addActivity.note"
            maxlength="100"
          ></b-form-input> -->
          <vue-editor v-model="addActivity.note" id="note-input"></vue-editor>
        </b-form-group>
        <input
          type="checkbox"
          v-model="addActivity.includeArchived_activity"
          name="includeArchived_activity"
        />
        <label for="includeArchived_activity" class="ml-2">Assegna ad archiviati</label>
      </form>
    </b-modal>

    <b-modal
      id="importContact"
      title="Importa Contatti"
      @hide="resetAddContact"
      ref="modal"
      centered
      @ok="handleOkContact"
      :ok-disabled="importContact.file == null"
      ok-only
      ok-title="Salva"
      header-class="text-primary"
      body-class="pb-0"
      footer-class="d-flex justify-content-start border-0 pt-0"
    >
      <p>
        <small
          >Vedi file
          <strong>
            <a v-bind:href="environment.exampleXlsx"
              target="_blank"
              class="text-primary">
              modello (.xlsx)
            </a>
          </strong>
        </small>
      </p>
      <hr />
      <form ref="form" @submit.stop.prevent="importContactAction()">
        <b-form-group
          label="Seleziona Tipologia Attività"
          label-for="activityType_Code"
          class="m-input"
        >
          <b-form-select
            id="activityType_Code"
            v-model="importContact.activityType_Code"
            :options="listTypeActivity"
            value-field="activity.id"
            text-field="activity.name"
            @change="getListImportCodeActivity"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Seleziona Attività"
          label-for="activity_Code"
          class="m-input"
        >
          <b-form-select
            id="activity_Code"
            v-model="importContact.activityImportCode"
            :options="listActivity"
            value-field="activity.importCode"
            text-field="activity.name"
            :disabled="checkListActivity !== true"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group class="mb-5">
          <b-form-file
            v-model="importContact.file"
            placeholder="Scegli un file o trascinalo qui..."
            drop-placeholder="Trascina il file qui..."
            accept=".csv, .xls, .xlsx"
          ></b-form-file>
        </b-form-group>
      </form>
    </b-modal>

    <b-modal
      id="modal-archivia"
      @ok="archiveSelect"
      size="sm"
      button-size="sm"
      buttonSize="sm"
      okVariant="danger"
      okTitle="YES"
      cancelTitle="NO"
      footerClass="p-2"
      hideHeaderClose
      centered
    >
      <p><strong>Conferma archiviazione</strong></p>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import apiAxios from "../libs/apiAxios";
import { VueEditor } from "vue2-editor";
import environment from "../environment";
export default {
  props: [
    "getRow",
    "listTypeActivity",
    "downloadMethod",
    "upadateContactList",
    "nContact",
    "selectionAll",
    "listCourses",
  ],

  name: "AppContactsActions",
  components: {
    VueEditor,
  },
  data() {
    return {
      baseContact: {
        id: null,
        firstName: "",
        lastName: "",
        birthDt: "",
        email: "",
        phoneNumber: "",
        activityType_Code: "",
        activity_Code: "",
        isPrivacy: false,
        isNewsletter: false,
      },

      newContact: {
        id: null,
        firstName: "",
        lastName: "",
        birthDt: "",
        email: "",
        phoneNumber: "",
        activityType_Code: "",
        activity_Code: "",
        isPrivacy: false,
        isNewsletter: false,
        contactsCourses: "",
      },

      addActivity: {
        contactUpdateActivity: null,
        activity_Code: null,
        note: null,
        eventDt: null,
        activityInfo: {},
        includeArchived_activity : false,
      },

      masks: {
        input: "DD/MM/YYYY HH:mm",
        output: "DD/MM/YYYY HH:mm",
      },

      listActivity: [],
      selectAll: false,
      importContact: {
        activityImportCode: null,
        file: null,
      },
    };
  },
  watch: {
    /*  menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }, */
  },

  created() {
    this.inittahun();
  },
  computed: {
    environment() {
      return environment
    },
    checkListActivity() {
      if (this.listActivity.length == 0) {
        return false;
      } else {
        return true;
      }
    },

    checkNewContact() {
      if (
        this.newContact.firstName != "" &&
        this.newContact.lastName != "" &&
        this.newContact.email != "" &&
        this.newContact.birthDt != "" &&
        this.newContact.isPrivacy != false
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkActivity() {
      if (
        this.addActivity.activity_Code != null &&
        this.addActivity.eventDt != null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    inittahun() {
      var max = new Date().getFullYear();
      var min = max - 100;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push(i);
      }

    /*   console.log(years); */
      return (this.listYear = years);
    },
    resetModal() {
      this.newActivity = { ...this.baseContact };
      this.listActivity = [];
    },
    addFakeDate() {
        this.addActivity.eventDt = moment(new Date('2020-12-10 03:00:00')).format();
    },
    addSelectAll() {
      /* console.log(this.selectAll); */
      if (this.selectAll === false) {
        this.$emit("selectRow", 1);
        this.getRow.forEach(function(contact) {
          /*  console.log("seleziona"); */

          contact.selection = true;
        });
      } else {
        this.$emit("selectRow", 0);
        this.getRow.forEach(function(contact) {
          /* console.log("deseleziona"); */

          contact.selection = false;
        });
      }
    },

    async getListActivity() {
      try {
        await this.$store
          .dispatch("activity/getActivitiesChildren", {
            id: this.addActivity.activityType_Code,
          })
          .then(() => {
            const getlistActivity = this.$store.getters[
              "activity/activitiesChildren"
            ];

            this.listActivity = getlistActivity.payload.activities;
            return this.listActivity;
          });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },

    async getListActivityNew() {
      try {
        await this.$store
          .dispatch("activity/getActivitiesChildren", {
            id: this.newContact.activityType_Code,
          })
          .then(() => {
            const getlistActivity = this.$store.getters[
              "activity/activitiesChildren"
            ];

            this.listActivity = getlistActivity.payload.activities;
            return this.listActivity;
          });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },
    async getListImportCodeActivity() {
      try {
        await this.$store
          .dispatch("activity/getActivitiesChildren", {
            id: this.importContact.activityType_Code,
          })
          .then(() => {
            const getlistActivity = this.$store.getters[
              "activity/activitiesChildren"
            ];

            this.listActivity = getlistActivity.payload.activities;
            return this.listActivity;
          });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },

    resetaddActivity() {
      this.addActivity = {
        contactUpdateActivity: null,
        activity_Code: null,
        activityInfo: null,
        note: null,
        eventDt: null,
      };

      this.listActivity = [];
    },

    saveLine() {
      var data = {
        firstName: this.newContact.firstName,
        lastName: this.newContact.lastName,
        birthDt: moment(String(this.newContact.birthDt)).format(),
        email: this.newContact.email,
        phoneNumber: this.newContact.phoneNumber,
        activityType_Code: this.newContact.activityType_Code,
        activity_Code: this.newContact.activity_Code,
        eventDt: moment(String(this.newContact.eventDt)).format(),
        notes: this.newContact.notes,
        isPrivacy: this.newContact.isPrivacy,
        isNewsletter: this.newContact.isNewsletter,
        contactsCourses: this.newContact.contactsCourses,
      };

      /* console.log(data); */
      this.$store.dispatch("contact/postContact", data);

      this.$nextTick(() => {
        this.$bvModal.hide("newContact");
      });
      this.$emit("updateList", 0);
      /* this.$router.go(); */
    },

    async addActivitySelect() {
      this.addActivity.activityInfo = this.activityById(
        this.addActivity.activity_Code
      );
      var dataGeneric = this.addActivity;
      var contactUpdateActivity = [];
      this.getRow.forEach(function(contact) {
        if (contact.selection == true) {
          const data = {
            id: 0,
            idContact: contact.id,
            idActivity: dataGeneric.activity_Code,
            note: dataGeneric.note,
            eventDt: moment(String(dataGeneric.eventDt)).format(),
          };

          contactUpdateActivity.push(data);
        }
      });

      this.addActivity.contactUpdateActivity = contactUpdateActivity;



      try {
        await this.$store
          .dispatch("contact/postAddActitivityToContact", this.addActivity)
          .then(() => {
            this.toast(
              `Assegna attività`,
              `Attività assegnata con successo`,
              "success",
              true
            );
            this.$nextTick(() => {
              this.$bvModal.hide("addActivitySelect");
            });
            return;
          });
      } catch (e) {
        console.log({ message: "Some error postAddActitivityToContact" });
      }


      this.$emit("updateList", 0);
    },

    async archiveSelect() {
      /*   this.addActivity.activityInfo = this.activityById(
        this.addActivity.activity_Code
      );
      var dataGeneric = this.addActivity; */
      var ids = [];
      this.getRow.forEach(function(contact) {
        if (contact.selection == true) {
          ids.push(contact.id);
        }
      });

      /*    this.addActivity.contactUpdateActivity = contactUpdateActivity; */

 /*      console.log(ids); */
      try {
        await this.$store
          .dispatch("contact/archiveGroupContacts", ids)
          .then(() => {
            this.$emit("updateList", 0);
          });
      } catch (e) {
        console.log({ message: "Some error archiveGroupContacts" });
      }

      /* this.$nextTick(() => {
        this.$bvModal.hide("addActivitySelect");
      }); */

      /* this.$emit("updateList", 0) */
    },

    handleOkActivity(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.addActivitySelect();
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.saveLine();
    },

    activityById($id) {
      const activityId = this.$store.getters["activity/activityById"]($id);

      return activityId;
    },

    rowClass(item) {
      if (item.filed === true) return "m-table--disable-row";
    },

    resetAddContact() {
      console.log("resetAddContact");
    },

    handleOkContact(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.importContactAction();
    },

    async importContactAction() {
      console.log("importContact");
      let formData = new FormData();
      formData.append(
        "activityImportCode",
        this.importContact.activityImportCode
      );
      formData.append("importFile", this.importContact.file);

      apiAxios
        .post(`api/foppacrm/contactsimports/fromxls`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.isSuccess == true) {
            this.$bvModal.hide("importContact");
            this.$emit("updateList", 0);
            this.toast(
              `Importa Contatti`,
              `Contatti importati con successo ${response.data.payload.imports[0].contactsSuccessNr} su ${response.data.payload.imports[0].contactsNr}`,
              "success",
              true
            );
          } else {
            console.log("error");
            this.$bvModal.hide("importContact");
            this.toast(
              `Importa Contatti`,
              `Errore importazione ${response.data.resultCodes[0].code}`,
              "warning",
              true
            );
            console.log(response);
          }
        })
        .catch((error) => console.log(error));
    },

    toast(title, message, variant, append = false) {
      this.counter++;
      this.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-bottom-right",
        solid: true,
        variant: variant,
        appendToast: append,
      });
    },
  },
};
</script>
